.map-footer-container {
  height: auto;
  width: auto;
  max-width: 36px;
  overflow: visible;
  position: absolute;
  z-index: 2;
  left: 12px;
  bottom: 12px;
}
.map-footer-container.map-footer-left {
  left: 12px;
}
.map-footer-container.map-footer-right {
  left: auto;
  right: 12px;
}

@media print {
  .map-footer-container {
    display: none;
  }
}